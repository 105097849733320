import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "@fontsource/league-spartan"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDev, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'


const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  font-family: "League Spartan";

  > div {
    max-width: 400px;
    text-align: center;
    padding: 20px;
  }

  a {
    display: block;
    max-width: 200px;
    margin: 0 auto;
  }
`



const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <LogoContainer>
      <div>
        {/* <Link to="/">
          <Logo />
        </Link> */}
        <h1>👋 I'm Viv</h1>
        <p>
          I am a high impact developer capable of working across various tech stacks and degrees of abstraction.
        </p>
        <div style={{ textAlign: `center`, display: "flex", flexDirection:"row", marginRight: '20%', marginLeft: '20%'}}>
          <a href={`https://www.linkedin.com/in/viv-paranjape/`}  >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href={`https://github.com/vivasvan`} >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
      </div>
    </LogoContainer>
  </Layout>
)

export default IndexPage
